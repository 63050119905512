import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Button, Card, Message, Icon } from 'semantic-ui-react'

const ApplicationsPage = ({ data }) => {
  const applicationItems = data.applications.edges.map(({ node }) => (
    <Card
      key={node.id}
      raised
      color="blue"
      style={{
        maxWidth: 400,
        // boxShadow: `3px 0 12px  rgba(0,0,0,0.5)`
      }}
    >
      <Card.Content textAlign="center">
        <Card.Header style={{ margin: '1em 0' }}>{node.title}</Card.Header>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button
            positive
            color="green"
            as="a"
            href={node.document.asset.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </Button>
          <Button basic color="orange" disabled>
            Online
          </Button>
        </div>
      </Card.Content>
    </Card>
  ))

  return (
    <Layout
      headerImage={data.headerImage.childImageSharp}
      headerText="Applications"
      headerSize="sm"
    >
      <SEO title="Pilgrim Lists" keywords={[`pilgrim lists`]} />
      <Message color="blue" style={{ marginBottom: '2rem' }}>
        <Message.Content>
          <Icon name="info circle" /> Online forms coming soon!
        </Message.Content>
      </Message>
      <Card.Group centered itemsPerRow={1}>
        {applicationItems}
      </Card.Group>
    </Layout>
  )
}

export default ApplicationsPage

export const query = graphql`
  query Applications {
    applications: allSanityApplication(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
          document {
            asset {
              url
            }
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "path.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
